<template>
  <div class="form-group flex justify-content-center align-item-center">
    <custom-file-loader
      ref="photoInput"
      @loading="imageOnLoad"
      :label="label"
      :name="name"
      :multiple="true"
      v-if="!disabled"
    />
    <div class="images-list">
      <div v-for="file in currentFiles" :key="file.name" class="image-item">
        {{ file.name }}
        <img
          src="@/assets/images/icons/close.svg"
          alt="Кнопка удаления файла из списка"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from "@vue/reactivity";
import CustomFileLoader from "./CustomFileLoader.vue";
import { computed, inject, onBeforeMount, watch } from "@vue/runtime-core";
import { notify } from "@kyvg/vue3-notification";

export default {
  components: { CustomFileLoader },
  name: "preview-file-loader",
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    images: {
      type: String,
    },
    disabled: {
      default: false,
    },
  },
  setup(props, context) {
    const previewImage = ref(null),
      photoInput = ref(null),
      imageUrl = inject("imageUrl");
    const onLoadImage = toRef(props, "image"),
      disabledInput = toRef(props, "disabled"),
      currentImage = computed(() =>
        onLoadImage.value && !onLoadImage.value.startsWith("data:image")
          ? `url(${imageUrl}/${onLoadImage.value})`
          : `url(${previewImage.value})`
      );
    const imageTypes = ["image/png", "image/jpeg", "image/jpg", "image/jfif"];
    const currentFiles = ref([]);

    const imageOnLoad = (files) => {
      if (files.length && checkFiles(files)) {
        for (
          let i = 0;
          i < files.length && currentFiles.value.length < 5;
          i++
        ) {
          currentFiles.value.push(files[i]);
        }
      } else {
        notify({
          type: "error",
          title: "Неверный формат файла",
        });
      }
    };

    const checkFiles = (files) => {
      for (let i = 0; i < files.length; i++) {
        if (!imageTypes.includes(files[0].type)) {
          return false;
        }
      }
      return true;
    };
    const imageWork = () => {
      if (!disabledInput.value) {
        if (previewImage.value) {
          previewImage.value = null;
          photoInput.value.clearInput();
          context.emit("delete");
        } else {
          photoInput.value.clickOnInput();
        }
      }
    };

    onBeforeMount(() => {
      if (onLoadImage.value) previewImage.value = onLoadImage.value;
    });

    watch(onLoadImage, () => {
      previewImage.value = onLoadImage.value;
    });

    return {
      previewImage,
      photoInput,
      imageOnLoad,
      imageWork,
      onLoadImage,
      currentImage,
      imageUrl,
      disabledInput,
      currentFiles,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  margin: 15px -15px;
  padding: 5px;
}

.images-list {
  border: 2px solid #ebebeb;
  border-radius: 10px;
  margin-left: 30px;

  .image-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 6px;
    &:not(:last-child) {
      border-bottom: 2px solid #ebebeb;
    }
    img {
      margin-left: 8px;
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
}

@media (max-width: 425px) {
  .form-group {
    flex-direction: column;
    margin-top: 0;
    width: 100%;
  }
}
</style>